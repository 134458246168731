@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/*------------------------------------------------------------
Project Name:HR-Clinician;
------------------------------------------------------------*/
/* Variables */
:root {
    --primary: #317361;
    --primaryDark: #182d44;
    --primaryLight: #f1fcfd;
    --primaryLinkColor: #2b66aa;
    --lightText: #a3a3a3;
    --darkText: #3e3e3e;
    --blackText: #000000;
    --whiteBg: #ffffff;
    --bodyBg: #f9f9f9;
    --lightBorderColor: #e9e9e9;
    --darkBorderColor: #d1d1d1;
    --lightWarning: #ecc287;
    --darkWarning: #f8824f;
    --lightSkyBlue: #62bee6;
    --lightPurple: #9695c5;
    --colorA7D8ED: #a7d8ed;
    --colorF4E5B0: #f4e5b0;
    --colorC1F0DF: #c1f0df;
    --colorF6D5CD: #f6d5cd;
    --colorEBEBEB: #ebebeb;
    --colorF1F1F1: #f1f1f1;
    --colorB5CFE8: #b5cfe8;
    --color324B58: #324b58;
    --colorFF5353: #ff5353;
    --color9BB6D5: #9bb6d5;
    --colorFDA53D: #fda53d;
    --colorFF9330: #ff9330;
    --color727272: #727272;
    --color9A895D: #9a895d;
    --colorCA40D6: #ca40d6;
    --colorECCE62: #ecce62;
    --colorB8DEED: #b8deed;
    --colorEBBB13: #ebbb13;
    --color-F8824F: #f8824f;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
html,
body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 20px;
    min-width: 320px;
    color: var(--darkText);
    background-color: var(--bodyBg);
    -ms-overflow-style: scrollbar;
}
::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}
::-webkit-scrollbar-thumb {
    border: 0 !important;
    background-color: #b2b2b2 !important;
    border-radius: 12px !important;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
.hover:hover {
    cursor: pointer;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
ul {
    list-style-type: none;
}
* {
    outline: none !important;
}
a, .link {
    text-decoration: none !important;
    transition: all 0.3s;
    color: var(--primaryLinkColor) !important;
    cursor: pointer;
}
a:hover,
a:focus {
    color: #254d81;
}
a.secondary-link:hover,
a.secondary-link:focus {
    color: #07689f;
}
/* .MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-select {
  max-width: calc(100% - 45px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} */

/* PageTitle With Action 
****************************************************************************/
.page-title-with-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
}
.page-title-with-action .title {
    color: var(--darkText);
    margin-right: 20px;
    margin-bottom: 14px;
}
.page-title-with-action .title span {
    font-weight: 300;
}
.page-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
    flex-grow: 0.001;
}
.page-actions button .MuiBadge-root {
    position: absolute;
    right: 0;
    top: 0;
}
.page-actions .icon-btn {
    padding: 6px;
    min-width: auto;
}
/* *********Tabs*************** */
.tab-block .MuiBox-root {
    padding-left: 0;
    padding-right: 0;
}
.tab-block .MuiTab-root + .MuiTab-root {
    margin-left: 20px;
}
.tab-block .MuiTab-root {
    min-width: auto;
}
/* Loader
****************************************************************************/
.loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
/* Date Picker
****************************************************************************/
.MuiFormControl-root.date-picker-control .MuiInputBase-root input,
.MuiFormControl-root.time-picker-control .MuiInputBase-root input,
.MuiFormControl-root.date-range-picker-control .MuiInputBase-root input {
    padding: 13px 0 13px 13px;
}
.MuiFormControl-root.date-picker-control .MuiFormLabel-root.MuiInputLabel-root,
.MuiFormControl-root.time-picker-control .MuiFormLabel-root.MuiInputLabel-root,
.MuiFormControl-root.date-range-picker-control .MuiFormLabel-root.MuiInputLabel-root {
    max-width: calc(100% - 60px);
}
.DatePickerWrapper .MuiPaper-root .css-j7qwjs .css-epd502 {
    width: 280px;
    min-height: 196px;
}
.DatePickerWrapper .MuiPaper-root .MuiCalendarPicker-root {
    /* width: 280px; */
    min-height: 196px;
}
.DatePickerWrapper .MuiPaper-root .MuiCalendarPicker-root .PrivatePickersSlideTransition-root {
    min-height: 216px;
}
.DatePickerWrapper
    .MuiPaper-root
    .MuiCalendarPicker-root
    .MuiCalendarPicker-viewTransitionContainer
    .css-1n2mv2k
    .MuiTypography-root {
    width: 32px;
    height: 36px;
}
.DatePickerWrapper .MuiPaper-root .MuiCalendarPicker-root .PrivatePickersYear-root .PrivatePickersYear-yearButton {
    width: 65px;
    height: 32px;
}
.DatePickerWrapper .MuiPaper-root .MuiDialogActions-root,
.DateRangePickerWrapper .MuiPaper-root .MuiDialogActions-root {
    display: none;
}
.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: #8ec9f9;
    color: #053a65;
}
.MuiPickersDay-root.Mui-selected {
    background-color: #0c81df !important;
    color: #ffffff !important;
}
.DateRangePickerWrapper .MuiPaper-root .css-1xhj18k .css-1tape97:not(:last-of-type) {
    border-right: 1px solid var(--darkBorderColor);
}
.DateRangePickerWrapper .MuiPaper-root .css-1xhj18k .css-1tape97 .PrivatePickersSlideTransition-root {
    min-height: 266px;
}
.date-picker-control .MuiInputLabel-root.Mui-error {
    color: #a3a3a3;
}

.phone-input .special-label {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.4375em;
    padding: 0 5px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    transform: translate(14px, -9px) scale(0.75);
    top: -2px !important;
    left: 0 !important;
    transform-origin: left center;
}
.phone-input .react-tel-input .form-control {
    padding: 11px 13px 13px 54px !important;
    width: 100%;
}
.phone-input-error .react-tel-input .form-control {
    border: 1px solid var(--colorFF5353);
}
.phone-input .react-tel-input .form-control:hover {
    border: 1px solid var(--primary);
    box-shadow: 0 0 0 0.1px var(--primary);
}
.phone-input .react-tel-input .form-control:focus {
    border: 1px solid var(--primary);
    box-shadow: 0 0 0 0.5px var(--primary);
}

/* --------------------------------------*/
.page-with-box {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 10px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
}
.account-heading {
    margin-bottom: 30px;
    text-align: center;
}
.secondary-link {
    font-size: 20px !important;
    color: #7e93be;
}
/* --------------------------------------*/

.form-group-with-select,
.form-group-with-select.MuiFormControl-root .MuiOutlinedInput-root {
    padding-left: 0 !important;
}
.form-group-with-select .MuiInputAdornment-root fieldset {
    border: 0;
}
.form-group-with-select .MuiInputAdornment-root .MuiSelect-select {
    position: relative;
    max-width: 100%;
}
.form-group-with-select .MuiInputAdornment-root .MuiSelect-select:after {
    position: absolute;
    right: 0;
    top: 15px;
    bottom: 15px;
    content: '';
    border-right: solid 1px #d1d1d1;
}

.white-box {
    background-color: var(--whiteBg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
}
@keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideInOut {
    0% {
      transform: translate(0, 80px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
.fade-animation{
    animation: fadeInOut 600ms ease-in-out, slideInOut 600ms ease-in-out;
}
.white-box-4px {
    background-color: var(--whiteBg);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
}

.white-box-5px {
    background-color: var(--whiteBg);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
}
.white-background {
    background-color: var(--whiteBg);
}
/* file-upload 
****************************************************************************/
.file-uploader {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.file-uploader .file-uploader-label {
    margin: 4px 10px 4px 6px;
}
.file-uploader .document-name-outer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 4px 0;
    max-width: calc(100% - 40px);
}
.file-uploader .document-name {
    flex-grow: 1;
    margin: 0 10px;
    border-bottom: 2px solid var(--primary);
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 55px);
}
.file-uploader .file-browse-btn {
    margin: 4px 0;
    margin-left: auto;
    font-weight: 400;
    padding: 5px 15px;
    font-size: 12px;
    line-height: 15px;
    border-radius: 4px;
}
.document-listing .document-name-outer {
    max-width: calc(100% - 95px);
}

.document-listing li {
    padding: 10px 0;
}
.document-listing li + li {
    border-top: 1px solid var(--darkBorderColor);
}
.document-listing .document-name-outer {
    max-width: calc(100% - 95px);
}
.document-listing .document-name {
    border-bottom: 2px solid var(--primary);
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.horizontal-630 {
    max-width: 630px !important;
    border-bottom: 1px solid #a8abaa;
}

/* --------sidebar------------- */

.over-layer {
    position: fixed;
    background: rgba(0, 0, 0, 0.45);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
}
.sidebar .over-layer {
    z-index: 2;
}
.show-sidebar .over-layer {
    opacity: 1;
    visibility: visible;
}
.show-search .header-search {
    margin-top: 0;
}
.sidebar {
    margin-left: -267px;
}
.show-sidebar .sidebar {
    margin-left: 0;
}

/* ============= */
.show-sidebar .sidebar .main-content {
    margin-left: 0;
    padding: 0;
}

/* ------------------login--------------------*/
.login-box {
    position: relative;
    /* width: 40%; */
    display: none;
    background: #f2f5f7;
}
.login-box-2 {
    background: #f2f5f7 !important;
    border-radius: 20px;
    align-items: center;
}
.advertisement-box {
    position: absolute;
    max-width: 660px;
}
.advertisement-box h1 {
    max-width: 380px;
    color: #7f7f7f;
    font-size: 35px;
    font-weight: 700;
    line-height: 48px;
}
.advertisement-box h2 {
    max-width: 337px;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}
.advertisement-box img {
    max-width: 115px;
}
/*---------main-layout-----------------*/
.main-content {
    transition: all 0.3s ease-in-out;
}

/* ---------------dashboard------------ */
.dashboard-tiles {
    width: 100%;
    border-radius: 15px;
}
.dashboard-tiles h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    padding: 10px 130px 0px 26px;
}
.dashboard-tiles h6 {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    /* margin: -25px 30px 0px 0px; */
    padding: 0 10px 10px 0;
}
/* .dashboard-container {
  padding: 36px 33px 0 44px;
} */
.dashboard-details {
    border-radius: 8px;
    border: 1px solid #d0d3d9;
}

/* Profile Steps
****************************************************************************/
.user-profile-img {
    width: 172px;
    height: 172px;
    margin: 0 auto 20px;
    display: block;
}
.user-profile-action-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-profile-action-btns button {
    border-radius: 50px;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d0d3d9;
}
.user-profile-action-btns button:hover {
    text-decoration: none;
    background-color: rgba(36, 58, 98, 0.04) !important;
    border: 1px solid rgb(36, 58, 98);
}
.minW-100p {
    min-width: 100px;
    width: 100px;
}
.minW-90p {
    min-width: 90px;
    width: 90px;
}
.minW-120p {
    min-width: 120px;
    width: 120px;
}
.minW-140p {
    min-width: 140px;
    width: 140px;
}
.fw-300 {
    font-weight: 300 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.form-content {
    max-height: calc(100vh - 260px) !important;
}
.vertical-line {
    display: inline-block;
    border-left: 1px solid #858d9d;
    margin: 0 10px;
    height: 100%;
}
.body1 {
    color: #77787d !important;
    display: block !important;
    margin-bottom: 6px !important;
}
.body2 {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    color: #263238 !important;
}
.policy-overview .info-box {
    background-color: #fff;
    border: 1px solid #dfe1e3;
    border-radius: 6px;
    overflow: hidden;
    /* height: 100%; */
}
.policy-overview .info-box .info-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    background-color: #f3f5f7;
}
.policy-overview .info-box-body {
    padding: 20px;
}
/* ****************** full-calendar ******************* */

.primary-fullCalendar .fc-theme-standard .fc-event{cursor: pointer;}
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-group{ background: var(--whiteBg); box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1); border-radius: 5px; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary{ background: var(--whiteBg); border-color: var(--whiteBg); border-radius: 0; height: 40px; color: var(--darkText) }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary:hover,.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-button-active{ background: var(--primary)!important; color: var(--whiteBg)!important; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk  .fc-button{ border: 0; box-shadow: none!important; outline: 0!important; transition: all 0.15s ease-in-out!important; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button:first-child{ border-top-left-radius: 5px; border-bottom-left-radius: 5px;}
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button:last-child{ border-top-right-radius: 5px; border-bottom-right-radius: 5px;}
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary .fc-icon{ display: flex; align-items: center; justify-content: center; width: 16px; height: 16px; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary .fc-icon::before{ content: none }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary .fc-icon-chevron-left{  background-image: url('../images/ic-chevron-left-grey.svg'); background-repeat: no-repeat; background-position: center center; background-size: 13px 13px; } 
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary .fc-icon-chevron-left:after{ content: ""; border-right: 1px solid var(--darkBorderColor); z-index: 1; position: absolute; top: 10px; bottom: 10px; right: 0px; overflow: visible; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary:hover .fc-icon-chevron-left:after{ opacity: 0; visibility: hidden; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary .fc-icon-chevron-right{ background-image: url('../images/ic-chevron-right-grey.svg'); background-repeat: no-repeat; background-position: center center; background-size: 13px 13px; } 
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-prev-button, .primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-next-button{ min-width: 40px; display: flex; align-items: center; justify-content: center; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-prev-button:hover, .primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-next-button:hover{ background: var(--primary)!important; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-prev-button:hover .fc-icon-chevron-left{ background-image: url('../images/ic-chevron-left-white.svg'); background-repeat: no-repeat; background-position: center center; background-size: 13px 13px; } 
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary.fc-next-button:hover .fc-icon-chevron-right{ background-image: url('../images/ic-chevron-right-white.svg')!important; background-repeat: no-repeat; background-position: center center; background-size: 13px 13px; } 

.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-today-button{ border-radius: 5px; background: var(--primary); color: var(--whiteBg); margin-left: 20px; padding: 8px 17px; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-dayGridMonth-button, .primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-timeGridWeek-button, .primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-timeGridDay-button{ min-width: 60px; }
.primary-fullCalendar .fc-theme-standard .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title{ font-size: 1.3rem; line-height: 1.2; text-align: center; }

/* calendar borders design */
.primary-fullCalendar .fc-theme-standard .fc-daygrid.fc-dayGridMonth-view .fc-scrollgrid-section-header th, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridWeek-view .fc-scrollgrid-section-header th, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid-section-header th{ border-bottom: 0; border-radius: 5px 5px 0 0; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid.fc-dayGridMonth-view .fc-scrollgrid-section-body > td, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridWeek-view .fc-scrollgrid-section-body > td,
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid-section-body > td{ border-radius: 0 0 5px 5px; overflow: hidden; }
.primary-fullCalendar .fc-theme-standard .fc-scrollgrid-section-header .fc-col-header .fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion{ font-size: 1rem; line-height: 1.2; color: var(--darkText) !important; min-height: 32px; display: flex; justify-content: center; align-items: center; }
.primary-fullCalendar .fc-theme-standard .fc-scrollgrid-section .fc-daygrid.fc-dayGridMonth-view .fc-daygrid-day-number{ font-size: 1rem; line-height: 1.2; color: var(--darkText) !important; padding: 7px 9px; }
.primary-fullCalendar .fc-theme-standard .fc-scrollgrid-section .fc-daygrid-day.fc-day-today{ background: none; }
.primary-fullCalendar .fc-theme-standard td, .primary-fullCalendar .fc-theme-standard th{ border: 1px solid var(--darkBorderColor); }
.primary-fullCalendar .fc-theme-standard td{ height: 100px; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid.fc-dayGridMonth-view .fc-scrollgrid, .primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridWeek-view .fc-scrollgrid, .primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid{ border: 0;}
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridWeek-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section, .primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section{ display: none; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridWeek-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section.fc-scrollgrid-section-liquid, .primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section.fc-scrollgrid-section-liquid{ display: table-row; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-timegrid-body .fc-timegrid-slots > table > tbody > tr:nth-child(2n) .fc-timegrid-slot{ border: 0; border-top: 1px dotted #000!important; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridWeek-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section.fc-scrollgrid-section-liquid .fc-timegrid-body .fc-timegrid-cols .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-col-events,
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section.fc-scrollgrid-section-liquid .fc-timegrid-body .fc-timegrid-cols .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-col-events{ margin: 0; }

.primary-fullCalendar .fc-theme-standard .fc-scrollgrid-section .fc-daygrid-day .fc-daygrid-day-number{ font-size: 1rem; line-height: 1.2; color: var(--darkText) !important; padding: 8px 8px 0 0; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event{ border-radius: 5px; padding: 0 5px 0 0; margin: 4px 5px!important; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event .fc-event-title-container { display: block; position: relative; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event .fc-daygrid-event-dot{ min-width: 5px; width: 5px; height: 5px; background-color: var(--darkText) !important ; border: 0; border-radius: 5px; margin: 0 6px; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event .fc-daygrid-event-dot-white{ min-width: 5px; width: 5px; height: 5px; background-color: #FFFFFF !important ; border: 0; border-radius: 5px; margin: 0 6px; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event .fc-event-time{ display: none;}
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event .fc-event-title{ display: block; position: relative; max-width: 100%; white-space: nowrap; text-overflow: ellipsis; color: #FFFFFF !important; font-size: 0.8rem; line-height: 15px; font-weight: 400; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-day-events .fc-daygrid-day-bottom{ margin: 0 5px!important; padding: 0; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-day-events .fc-daygrid-day-bottom .fc-daygrid-more-link.fc-more-link{ font-size: 0.8rem; line-height: 1.2; color: var(--primary); }

.primary-fullCalendar .fc-non-business{background: var(--colorEBEBEB)!important; display: flex; align-items: center; justify-content: center;}
.primary-fullCalendar .fc-non-business:before{ content: "Not Working"; font-size: 0.8rem; line-height: 1.2; font-weight: 500;}

/* Events Style */
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event.ipo-open, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event.ipo-open{ background: #ff5f15; border: 1px solid #ff5f15; }

.primary-fullCalendar .fc-theme-standard .fc-daygrid-event.ipo-close, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event.ipo-close{ background: #FF5963; border: 1px solid #FF5963; }

.primary-fullCalendar .fc-theme-standard .fc-daygrid-event.ipo-allotment, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event.ipo-allotment{ background: #228b22; border: 1px solid #228b22; }

.primary-fullCalendar .fc-theme-standard .fc-daygrid-event.ipo-refund, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event.ipo-refund{ background: #317361; border: 1px solid #317361; }

.primary-fullCalendar .fc-theme-standard .fc-daygrid-event.ipo-credit-share, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event.ipo-credit-share{ background: #29B18C; border: 1px solid #29B18C; }

.primary-fullCalendar .fc-theme-standard .fc-daygrid-event.ipo-list, 
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event.ipo-list{ background: #800080; border: 1px solid #800080; }

.primary-fullCalendar .fc-theme-standard .fc-timegrid-slots .fc-timegrid-slot{ height: 35px; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid-slots .fc-timegrid-slot.fc-timegrid-slot-label{ font-size: 1rem; line-height: 1.2; color: var(--darkText); }
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event{ box-shadow: none; margin: 0; border-radius: 0; left: 0; right: 0; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event .fc-event-main{ padding: 2px 5px; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event .fc-event-time{ font-size: 0.8rem; line-height: 1.2; color: var(--darkText); margin-bottom: 0; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid-event .fc-event-title{ display: block; color: var(--darkText); font-size: 0.96666667rem; line-height: 1.2; max-width: 100%; white-space: nowrap; text-overflow: ellipsis; }

.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section .fc-timegrid-event .fc-event-main{ padding: 8px 10px; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section .fc-timegrid-event .fc-event-main .fc-event-main-frame{ flex-direction: row; }
.primary-fullCalendar .fc-theme-standard .fc-timegrid.fc-timeGridDay-view .fc-scrollgrid tbody[role="rowgroup"] .fc-scrollgrid-section .fc-timegrid-event .fc-event-main .fc-event-main-frame .fc-event-time{ margin-right: 6px; line-height: 1.5; }

/* more event popup */
.primary-fullCalendar .fc-theme-standard .fc-more-popover{ background: var(--whiteBg); border: 0; box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2); border-radius: 5px; overflow: hidden; width: 360px; max-width: 360px; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-header { background: var(--whiteBg); border-bottom: 1px solid var(--colorF1F1F1); padding: 8px 8px 8px 10px; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-header .fc-popover-title{ font-size: 1rem; line-height: 1.2; color: var(--darkText); margin: 0 4px 0 0; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-header .fc-popover-close{ opacity: 1; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-header .fc-popover-close:hover{ opacity: 0.65; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-body { padding: 5px 10px; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-body .fc-daygrid-event-harness .fc-daygrid-event{ margin: 5px 0!important; padding: 4px 4px 4px 0; }
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-body .fc-daygrid-event-harness .fc-event-title-container::before{ left: -10px; top: 8px; } 
.primary-fullCalendar .fc-theme-standard .fc-more-popover .fc-popover-body .fc-daygrid-event-harness .fc-daygrid-event .fc-event-main .fc-event-title-container .fc-event-title{ font-size: 0.93333337rem; line-height: 1.4; }
.primary-fullCalendar .fc-theme-standard .fc-daygrid-event .fc-daygrid-event { display: flex; }

.primary-fullCalendar .fc-view-harness{ overflow: auto; min-height: 450px; }
.primary-fullCalendar .fc-view-harness .fc-daygrid, .primary-fullCalendar .fc-view-harness .fc-timegrid{ min-width: 700px; }

.primary-fullCalendar.time-grid-day-view .fc-toolbar-title{text-align: left!important; font-weight: 400!important; font-size: 1.33333333333rem!important;}
.primary-fullCalendar.time-grid-day-view .fc-toolbar-title:before{content:"Clinician’s Schedule on"; font-size: 0.8rem; line-height: 1.06666667; display: block;}
.primary-fullCalendar.time-grid-day-view .fc-view-harness{background: var(--whiteBg);}
.primary-fullCalendar.time-grid-day-view .fc-view-harness .fc-daygrid, .primary-fullCalendar.time-grid-day-view .fc-view-harness .fc-timegrid{min-width: auto;}
.primary-fullCalendar.time-grid-day-view .fc-direction-ltr .fc-timegrid-more-link{color: white; background-color: var(--color-F8824F);}
.primary-fullCalendar.time-grid-day-view .fc-theme-standard .fc-more-popover{ left: 47px !important; right: auto !important;}

/* Status Rounded Dot 
****************************************************************************/
.status-rounded{ display: inline-block; vertical-align: middle; width: 12px; height: 12px; border-radius: 50%; }
.status-rounded.ipo-open{ background: #ff5f15; }
.status-rounded.ipo-upcoming{ background: #F9CF58; }
.status-rounded.ipo-close{ background: #FF5963; }
.status-rounded.ipo-allotment{ background: #228b22; }
.status-rounded.ipo-refund {background: #317361; }
.status-rounded.ipo-credit-share{ background: #29B18C; }
.status-rounded.ipo-list{ background: #800080; }
.status-rounded.colorPrimary{ background: var(--primary); }
.status-rounded.color156262{ background: var(--color156262); }

@media (min-width: 600px) {
    .main-content {
        padding: 100px 30px 20px 30px;
    }
}

@media (max-width: 600px) {
    .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
        width: 80px !important;
    }
    /* .form-field {
    width: 130px !important;
  } */
}

@media (max-width: 599.98px) {
    .main-content {
        padding: 76px 10px 20px;
    }
    .body2{
        font-size: 14px !important;
    }
}
@media (min-width: 900px) {
    .main-content {
        padding: 113px 33px 40px 300px;
    }
    .sidebar {
        margin-left: 0;
        padding-top: 0;
    }
    .show-sidebar .sidebar {
        margin-left: -267px;
    }
    .show-sidebar .over-layer {
        opacity: 0;
        visibility: hidden;
    }
    .show-sidebar .main-content {
        padding: 113px 33px 40px 33px;
    }
}

@media (min-width: 1200px) {
    .main-content {
        padding: 113px 33px 40px 300px;
    }
    .login-box {
        display: flex;
    }
    .login-box-2 {
        background: #ffffff !important;
        /* width: 60%; */
    }
    .show-sidebar .main-content {
        padding: 113px 33px 40px 33px;
    }
    .advertisement-box {
        width: 450px;
        bottom: 20px;
    }
}
