:root {
    --primary: #244264;
    --primaryDark: #182d44;
    --primaryLight: #f1fcfd;
    --primaryLinkColor: #2b66aa;
    --lightText: #a3a3a3;
    --darkText: #3e3e3e;
    --blackText: #000000;
    --whiteBg: #ffffff;
    --bodyBg: #f9f9f9;
    --lightBorderColor: #e9e9e9;
    --darkBorderColor: #d1d1d1;
    --lightWarning: #ecc287;
    --darkWarning: #f8824f;
    --lightSkyBlue: #62bee6;
    --lightPurple: #9695c5;
    --colorA7D8ED: #a7d8ed;
    --colorF4E5B0: #f4e5b0;
    --colorC1F0DF: #c1f0df;
    --colorF6D5CD: #f6d5cd;
    --colorEBEBEB: #ebebeb;
    --colorF1F1F1: #f1f1f1;
    --colorB5CFE8: #b5cfe8;
    --color324B58: #324b58;
    --colorFF5353: #ff5353;
    --color9BB6D5: #9bb6d5;
    --colorFDA53D: #fda53d;
    --colorFF9330: #ff9330;
    --color727272: #727272;
    --color9A895D: #9a895d;
    --colorCA40D6: #ca40d6;
    --colorECCE62: #ecce62;
    --colorB8DEED: #b8deed;
    --colorEBBB13: #ebbb13;
}

.text-collapse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}
.form-control-type-two .MuiInputBase-root.MuiOutlinedInput-root.Mui-error input {
    border: 1px solid red;
}
.select-control-type-two .MuiInputBase-root.MuiOutlinedInput-root.Mui-error input {
    border: 1px solid red;
}

.bg-F9CF58.MuiChip-filled {
    color: #000000 !important;
    background: #F9CF58 !important;
}
.bg-ff5f15.MuiChip-filled {
    color: #FFFFFF !important;
    background: #ff5f15 !important;
}
.bg-ff5963.MuiChip-filled {
    color: #FFFFFF !important;
    background: #ff5963 !important;
}
.bg-228B22.MuiChip-filled {
    color: #FFFFFF !important;
    background: #228B22 !important;
}
.bg-800080.MuiChip-filled {
    color: #FFFFFF !important;
    background: #800080 !important;
}
.bg-317361.MuiChip-filled {
    color: #FFFFFF !important;
    background: #317361 !important;
}
.bg-29B18C.MuiChip-filled {
    color: #FFFFFF !important;
    background: #29B18C !important;
}
.key-data {
    color: #8A8888 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: block;
    font-weight: 500 !important;
}
.value-data {
    color: #4f84c0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: block;
}

.otp-block .MuiFormHelperText-root {
    margin-top: 12px;
}
.form-control-type-two .MuiInputBase-root.MuiOutlinedInput-root.Mui-error input {
    border: 1px solid red;
}
.select-control-type-two .MuiInputBase-root.MuiOutlinedInput-root.Mui-error input {
    border: 1px solid red;
}
.group-list table td:nth-child(3) {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
}
.mbs-items table td:nth-child(2) {
    max-width: 400px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.primary-fullCalendar .fc-theme-standard .fc-event {
    cursor: pointer;
}
.insurance-info {
    padding-left: 5px;
    vertical-align: middle;
}
a.file {
    word-break: break-all;
    cursor: pointer;
}
.text-collapse,
.text-collapse-144 {
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-collapse-144 {
    max-width: 144px;
}
.text-collapse {
    max-width: 200px;
}
.patient-detail {
    max-height: calc(100vh - 370px);
    overflow: auto;
    margin: 0 -20px;
    padding: 10px 20px;
}
.report-detail {
    max-height: calc(100vh - 250px);
    overflow: auto;
    margin: 0 -20px;
    padding: 10px 20px;
}
.date-picker-control .MuiInputLabel-root.Mui-error {
    color: #a3a3a3;
}
.auto-complete .MuiInputLabel-root.Mui-error {
    color: #a3a3a3;
}
.multi-auto-complete .MuiAutocomplete-input {
    max-width: 10px;
    min-width: 0px !important;
}
.auto-complete-renderTags {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 50px);
}
.max-100 {
    max-width: 100px;
}
.max-150 {
    max-width: 150px;
}
.max-200 {
    max-width: 200px;
}
.max-250 {
    max-width: 250px;
}
.max-300 {
    max-width: 300px;
}
.max-350 {
    max-width: 350px;
}
.max-400 {
    max-width: 400px;
}
.max-450 {
    max-width: 450px;
}
.max-500 {
    max-width: 500px;
}
.max-600 {
    max-width: 600px;
}
.max-700 {
    max-width: 700px;
}

.horizonatal-separator{border-bottom: 2px solid #8A8888;margin-top: 5px;}
.vertical-saperator{border: 1px solid #8A8888;margin-left: 15px;margin-right: 15px;}
.calendar-tile{border-left: 8px solid #317361;padding: 15px;border-radius: 5px;background-color: var(--whiteBg); box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}
.calendar-tile-yellow{border-left: 8px solid #F9CF58;padding: 15px;border-radius: 5px;background-color: var(--whiteBg); box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}
.calendar-tile-orange{border-left: 8px solid #ff5f15;padding: 15px;border-radius: 5px;background-color: var(--whiteBg); box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}


.text-time{min-width:106px;text-align:center;display:inline-block;font-size:12px;line-height:16px;color:#AAB1BC;font-weight:700;margin-bottom:15px;}
.timeline-block{position:relative;padding-left:90px;padding-bottom:29px;}
.timeline-block:last-child{padding-bottom:0px;}
.timeline-block::before{content:'';position:absolute;top:9px;left:15px;width:2px;border-left:2px dashed #B8BAC1;bottom:0;}
.timeline-block .circle-thumb{width:32px;height:32px;border-radius:50%;background-color:#317361;color:#fff;font-size:14px;line-height:18px;display:flex;align-items:center;justify-content:center;position:absolute;top:9px;left: 0;}
.timeline-block .circle-thumb-yellow{width:32px;height:32px;border-radius:50%;background-color:#F9CF58;color:#fff;font-size:14px;line-height:18px;display:flex;align-items:center;justify-content:center;position:absolute;top:9px;left: 0;}
.timeline-block .circle-thumb-orange{width:32px;height:32px;border-radius:50%;background-color:#ff5f15;color:#fff;font-size:14px;line-height:18px;display:flex;align-items:center;justify-content:center;position:absolute;top:9px;left: 0;}

.table-bg-none{background-color: #FFFFFF !important;}
.MuiCircularProgress-colorPrimary{color: #317361 !important;}
.card-sub-box{text-align: center;box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);border-radius: 15px;padding: 15px;flex: 1;display: flex;justify-content: center;align-items: center;flex-direction: column; }
/* Sales Card */
.gmp-card {
    padding: 18px 20px 25px;
}
.gmp-card .card-header {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    padding-bottom: 30px;
    flex-wrap: wrap;
}
.gmp-card .legend-wrapper {
    gap: 12px;
}
.gmp-card .legend-wrapper,
.gmp-card .legend-wrapper li {
    display: flex;
    align-items: center;
}
.gmp-card .legend-wrapper li {
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    white-space: nowrap;
}
.gmp-card .legend-wrapper span {
    height: 16px;
    width: 16px;
    display: block;
    margin-right: 6px;
    border-radius: 50%;
}
.gmp-card .legend-wrapper .primary {
    background-color: #317361;
}

.table-logo{display:flex;align-items:center;}
.table-logo p{padding: 0;}
.table-logo .MuiAvatar-root{width:48px;height:48px;margin-right:14px;padding: 6px;}

@media (min-width: 900px) {
    .personal-detail {
        min-width: calc(100% - 290px);
    }
}
/* #8A8888 */
@media (min-width: 1200px) {
    .editor-placeholder {
        max-width: 25% !important;
    }
}
